import { withProps } from '@synthetica-ai/system';
import { isAuthenticated } from 'models/user';
import { Route as RouterRoute, Redirect } from 'react-router-dom';

const Route = ({
  isAuthenticated,
  isPrivate = false,
  ...routerProps
}) => (isAuthenticated || !isPrivate ? (
  <RouterRoute {...routerProps} />
) : (
  <Redirect to="/" />
));

export default withProps({
  isAuthenticated,
})(Route);
