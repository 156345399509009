import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import ModelProvider from './ModelProvider';
import './root.css';

const Providers = ({
  options,
  theme,
  children,
}) => (
  <ModelProvider options={options}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  </ModelProvider>
);

export default Providers;
StyledEngineProvider;
