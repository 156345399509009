import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  isApplicationLoading: false,
  info: {},
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.login, (state) => {
    state.isLoading = true;
  })
  .addCase(actions.login.fail, (state) => {
    state.isLoading = false;
  })
  .addCase(actions.checkToken, (state) => {
    state.isAuthenticated = undefined;
    state.isApplicationLoading = true;
  })
  .addCase(actions.checkToken.fail, (state) => {
    state.isApplicationLoading = false;
    state.isAuthenticated = false;
  })
  .addCase(actions.checkToken.success, (state) => {
    state.isAuthenticated = true;
    state.isApplicationLoading = false;
  })
  .addCase(actions.changePassword, (state) => {
    state.isLoading = true;
  })
  .addCase(actions.updateUser, (state, { payload }) => {
    state.info = payload;
    state.isAuthenticated = true;
  })
  .addCase(actions.updateLoadingState, (state, { payload }) => {
    state.isLoading = payload;
  })
  .addCase(actions.resetUserState, () => initialState));

export default reducer;
