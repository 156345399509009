import ChangePassword from './change-password';
import Login from './login';
import Products from './products';

const routes = {
  login: {
    id: 'login',
    path: '/',
    exact: true,
    component: Login,
    isPrivate: false,
    enabled: true,
  },
  changePassword: {
    id: 'change-password',
    path: '/change-password',
    component: ChangePassword,
    isPrivate: true,
    enabled: false,
  },
  products: {
    id: 'products',
    path: '/products',
    component: Products,
    isPrivate: true,
    enabled: true,
  },
};

export const routesList = Object.fromEntries(
  Object
    .entries(routes)
    .map(([key, { component, ...route }]) => [key, route]),
);

export default routes;
