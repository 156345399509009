import { LogLevel } from '@azure/msal-browser';
import { BASE_URL } from 'constants/urls';

export const msalConfig = {
  auth: {
    clientId: process.env.WEBAPP_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${BASE_URL}/`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const tokenRequest = {
  scopes: ['User.Read', 'Mail.Read'],
  forceRefresh: false,
};
