import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingCover = ({
  children,
  full = false,
  isLoading,
  text = 'Loading ...',
}) => (
  isLoading ? (
    <Box
      display="flex"
      flexDirection="column"
      width={full ? '100vw' : 'auto'}
      height={full ? '100vh' : 'auto'}
    >
      <Box
        margin="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CircularProgress />
        <Typography style={{ marginTop: '0.5rem' }}>{text}</Typography>
      </Box>
    </Box>
  ) : (
    children || null
  ));

export default LoadingCover;
