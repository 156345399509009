import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Popover,
  Typography,
  IconButton,
} from '@mui/material';
import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { userInfo, logout, changePassword } from 'models/user';
import { routesList } from 'routes/config';

const changePasswordPath = routesList.changePassword.path;

const LoggedUser = ({
  logout,
  userInfo,
  changePassword,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Flex center>
        <Typography color="primary" variant="caption">
          {startCase(userInfo.username)}
        </Typography>
        <IconButton onClick={handleClick}>
          <AccountCircle color="primary" fontSize="large" />
        </IconButton>
      </Flex>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Flex
          direction="column"
          gap="0.5rem"
          sx={{
            p: '1rem',
            '& > *': {
              cursor: 'pointer',
            },
          }}
        >
          <Typography
            variant="button"
            onClick={logout}
          >
            Logout
          </Typography>
          {/* <Typography
            onClick={() => history.push(changePasswordPath)}
            variant="button"
          >
            Change Password

          </Typography> */}
        </Flex>
      </Popover>
    </Box>
  );
};

export default withProps({
  logout,
  userInfo,
  changePassword,
})(LoggedUser);
