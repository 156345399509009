import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: ({ isExpanded, canExpand }) => ({
    position: 'relative',
    width: '100%',
    padding: '2rem 1.5rem',
    minHeight: '25rem',
    cursor: canExpand ? 'pointer' : 'unset',
    maxWidth: isExpanded ? '100%' : '20rem',
    transition: theme.transitions.create(['all', 'transform'], {
      duration: theme.transitions.duration.complex,
    }),
  }),
  icon: {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    width: '64px',
    height: '64px',
    fill: 'red',
  },
}));
