import { localStorageValue } from '@synthetica-ai/utils/storage';
import { REFRESH_TOKEN, TOKEN } from '@synthetica-ai/constants';
import { AUTH_URL } from 'constants/urls';

const refreshToken = localStorageValue(REFRESH_TOKEN);
const token = localStorageValue(TOKEN);

export const loginService = ({ payload }) => ({
  url: `${AUTH_URL}/login`,
  method: 'POST',
  body: payload,
});

export const logoutService = () => ({
  url: `${AUTH_URL}/logout`,
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
  },
});

export const getAuthTokenService = () => ({
  url: `${AUTH_URL}/token`,
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
  },
});

export const changePasswordService = ({ payload }) => ({
  url: `${AUTH_URL}/changePassword`,
  method: 'POST',
  body: {
    ...payload,
    token: token.get(),
  },
});

export const checkTokenService = () => ({
  url: `${AUTH_URL}/checkToken`,
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
    token: token.get(),
  },
});

export const validateAzureToken = ({ payload }) => ({
  url: `${AUTH_URL}/validate-azure-token`,
  method: 'POST',
  body: {
    token: payload,
  },
});
