import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 10,
    height: '5rem',
    width: '100%',
    backgroundColor: 'white',
    padding: '0 2rem',
    position: 'sticky',
    top: '0',
  },
  logo: {
    width: '4rem',
    fill: theme.palette.primary.main,
  },
}));
