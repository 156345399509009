import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { systemEpics } from '@synthetica-ai/system/epics';

import { reducer as ui, epics as uiEpics } from 'models/ui';
import { reducer as user, epics as userEpics } from 'models/user';
import { reducer as app, epics as appEpics } from 'models/app';
import { startApplication } from 'models/app/actions';
import configStore from './configStore';

const ModelProvider = ({ options, children }) => {
  const rootReducer = combineReducers({
    ui,
    app,
    user,
  });

  const rootEpic = combineEpics(
    uiEpics,
    appEpics,
    userEpics,
    systemEpics,
  );

  const epicMiddleware = createEpicMiddleware({
    dependencies: { options },
  });

  const middleWares = [epicMiddleware];
  const store = configStore(rootReducer, {}, middleWares);
  epicMiddleware.run(rootEpic);
  store.dispatch(startApplication());

  return <Provider store={store}>{children}</Provider>;
};

export default ModelProvider;
