import { Button, Box, IconButton } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { withProps } from '@synthetica-ai/system';
import { isAuthenticated, logout } from 'models/user';
import { SyntheticaHeaderLogo } from 'assets/icons';

import { NavigationList, LoggedUser } from './components';
import { options } from './constants';
import { useStyles } from './styles';

const Header = ({ isAuthenticated }) => {
  const classes = useStyles();
  const history = useHistory();
  const isInHomepage = history.location.pathname == '/';

  return (
    <Box
      component="header"
      className={classes.header}
    >
      <Link to="/">
        <IconButton sx={{ p: 0 }}>
          <SyntheticaHeaderLogo className={classes.logo} />
        </IconButton>
      </Link>

      {isInHomepage && <NavigationList options={options} />}

      {!isAuthenticated
        ? (
          <Link
            to="/login"
            style={{ textDecoration: 'none' }}
          >
            <Button
              sx={{ backgroundColor: 'primary.main' }}
              variant="contained"
            >
              Login
            </Button>
          </Link>
        )
        : (
          <LoggedUser />
        )}
    </Box>

  );
};

export default withProps({
  isAuthenticated,
  logout,
})(Header);
