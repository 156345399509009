import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import { LoadingCover, Route } from 'components';
import { withProps } from '@synthetica-ai/system';
import { isApplicationLoading, isAuthenticated } from 'models/user';

import routes from './config';

const Routes = ({ isAuthenticated, isApplicationLoading }) => (
  <LoadingCover
    full
    isLoading={isApplicationLoading || isAuthenticated === undefined}
  >
    <Router>
      <Switch>
        {Object.values(routes)
          .filter(({ enabled }) => enabled)
          .map((routeProps, index) => (
            <Route
              key={index}
              isAuthenticated={isAuthenticated}
              {...routeProps}
            />
          ))}

        <Route
          path="*"
          isAuthenticated={isAuthenticated}
          render={() => <Redirect to="/" />}
        />
      </Switch>
    </Router>
  </LoadingCover>
);

export default withProps({
  isAuthenticated,
  isApplicationLoading,
})(Routes);
