import { Redirect } from 'react-router-dom';

import { useProps } from '@synthetica-ai/system';
import { isAuthenticated } from 'models/user';

const modelProps = { isAuthenticated };

const withRedirect = (redirectTo) => (Component) => (props) => {
  const { isAuthenticated } = useProps(modelProps);

  return isAuthenticated ? (
    <Redirect push to={redirectTo} />
  ) : (
    <Component {...props} />
  );
};

export default withRedirect;
