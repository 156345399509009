import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
  snackbar: {
    isOpen: false,
    type: 'success',
    message: '',
  },
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.openSnackbar, (state, { payload }) => {
    state.snackbar.isOpen = true;
    state.snackbar.type = payload.type;
    state.snackbar.message = payload.message;
  })
  .addCase(actions.closeSnackbar, () => initialState));

export default reducer;
