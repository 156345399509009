import { Typography, Box } from '@mui/material';
import { withProps } from '@synthetica-ai/system';
import { isAuthenticated } from 'models/user';

import { useStyles } from './styles';

const Sections = ({ options, isAuthenticated }) => {
  const classes = useStyles();
  return (
    <Box
      component="nav"
      className={classes.section}
    >
      {options
        ?.filter((datum) => !datum.isPrivate || isAuthenticated)
        ?.map(({ title, id, isPrivate }, index) => (
          <Typography
            key={index}
            color="primary"
            variant="h5"
            href={isPrivate ? `/${id}` : `/#${id}`}
            component="a"
          >
            {title}
          </Typography>
        ))}
    </Box>
  );
};

export default withProps({
  isAuthenticated,
})(Sections);
