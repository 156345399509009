import {
  Snackbar as MuiSnackbar,
  Alert,
  Slide,
} from '@mui/material';
import { withProps } from '@synthetica-ai/system';
import { closeSnackbar, snackbar } from 'models/ui';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Snackbar = ({ snackbar, closeSnackbar }) => {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackbar();
  };

  return (
    <MuiSnackbar
      open={snackbar.isOpen}
      onClose={handleClose}
      autoHideDuration={3000}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.type}
        elevation={6}
        variant="filled"
      >
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  );
};

export default withProps({
  snackbar,
  closeSnackbar,
})(Snackbar);
