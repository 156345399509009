import { CssBaseline } from '@mui/material';

import Providers from 'providers';
import { Routes } from 'routes';
import { theme } from 'assets/theme';
import Snackbar from 'components/Snackbar';

const App = () => (
  <Providers
    options={{ appName: '' }}
    theme={theme}
  >
    <Snackbar />
    <CssBaseline />
    <Routes />
  </Providers>
);

export default App;
