import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './authConfig';

const myMSALObj = new PublicClientApplication(msalConfig);

export const redirectPromise = () => myMSALObj.handleRedirectPromise();

export const signInWithAzure = () => {
  myMSALObj.loginRedirect(loginRequest);
};
