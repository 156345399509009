import { generateScopedActionCreator } from '@synthetica-ai/system';

const { createEffectAction } = generateScopedActionCreator('USER');

export const login = createEffectAction('LOGIN');
export const loginWithAzure = createEffectAction('LOGIN_WITH_AZURE');
export const logout = createEffectAction('LOGOUT');
export const checkToken = createEffectAction('CHECK_TOKEN');
export const changePassword = createEffectAction('CHANGE_PASSWORD');
export const updateUser = createEffectAction('UPDATE_USER');
export const resetUserState = createEffectAction('RESET_USER_STATE');
export const updateLoadingState = createEffectAction('UPDATE_LOADING_STATE');
export const validateAzureToken = createEffectAction('VALIDATE_AZURE_TOKEN');
