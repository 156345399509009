import { EMPTY, iif } from 'rxjs';
import download from 'downloadjs';
import { combineEpics } from 'redux-observable';
import { USER } from '@synthetica-ai/constants';
import { ofType, httpRequest } from '@synthetica-ai/system';
import { localStorageValue } from '@synthetica-ai/utils/storage';
import {
  mergeMap,
  ignoreElements,
  tap,
  map,
} from 'rxjs/operators';

import { checkToken } from 'models/user';
import { openSnackbar } from 'models/ui';
import { emptyAction } from 'utils/epics';

import * as actions from './actions';
import * as services from './services';

const user = localStorageValue(USER);
const hasUser = () => user.get();

const startApplicationEpic = (action$) => action$.pipe(
  ofType(actions.startApplication),
  mergeMap(() => iif(
    hasUser,
    [checkToken()],
    emptyAction(),
  )),
);

const downloadPdfEpic = (action$) => action$.pipe(
  ofType(actions.downloadPdf),
  tap(() => download('/elevate.pdf')),
  ignoreElements(),
);

const sendMessageEpic = (action$) => action$.pipe(
  ofType(actions.sendMessage),
  httpRequest(action$, services.sendMessageService),
);

const sendMessageSuccessEpic = (action$) => action$.pipe(
  ofType(actions.sendMessage.success),
  map(({ payload }) => openSnackbar({
    type: 'success',
    message: payload,
  })),
);

export default combineEpics(
  downloadPdfEpic,
  startApplicationEpic,
  sendMessageEpic,
  sendMessageSuccessEpic,
);
