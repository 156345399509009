import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  expandMore: ({ expanded }) => ({
    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create(['all', 'transform'], {
      duration: theme.transitions.duration.short,
    }),
  }),
}));
