import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    width: 'fit-content',
    margin: 'auto',
    '& > a': {
      padding: '0 1rem',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));
